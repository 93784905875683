import { useEffect, useRef, useState } from "react"
import { DecalarationData, FieldError } from "../../../app/models/declarationCreate"
import Form, { CustomRule, GroupItem, Item, } from 'devextreme-react/form'
import { getErrorMessage, isFieldValid } from "../../../app/common/utils/declarationErrorFunctions"
import ValidationEngine from 'devextreme/ui/validation_engine';

interface Props{
    formData: DecalarationData | null;
    fieldErrors: FieldError[];
    isTemplate : boolean;
    isFieldVisible: (fieldName: string) => boolean;
}

export default function SafetyAndSecurityReferencesRB({formData, fieldErrors, isTemplate, isFieldVisible}: Props){
    const [isFormReady, setIsFormReady] = useState(false)
    const formRef = useRef<Form>(null)
    const lrnText = (isTemplate) ? "Template Name" : "Local Reference Number";

    const currentValidateForm = () => {
        if(formRef.current && ValidationEngine.getGroupConfig(formRef.current.instance)){
            formRef.current.instance.validate()
        }
    }

    useEffect(() => {
    if(isFormReady)
            currentValidateForm()
    }); 

    const validationRules: {
        commercialReferenceNumberRule: boolean
        additionalInformationRule: boolean
        transportDocumentTypeRule: boolean
        transportDocumentNumberRule: boolean
        lrnRule: boolean
        conveyanceReferenceNumberRule: boolean
    } = {
        commercialReferenceNumberRule: isFieldValid("commercialReferenceNumber", fieldErrors),
        additionalInformationRule: isFieldValid("additionalInformation", fieldErrors),
        transportDocumentTypeRule: isFieldValid("transportDocumentType", fieldErrors),
        transportDocumentNumberRule: isFieldValid("transportDocumentNumber", fieldErrors),
        lrnRule: isFieldValid("localReferenceNumber", fieldErrors),
        conveyanceReferenceNumberRule: isFieldValid("conveyanceReferenceNumber", fieldErrors)
    };

    const lrnOptions = {maxLength: 22};

    return <Form ref={formRef} formData={formData} onContentReady={() => {setIsFormReady(true)}}>
        <GroupItem cssClass="cds-group-item" colCount={2} caption={'References'} >
            <Item dataField="referencesEns.commercialReferenceNumber" label={{text: "Commericial Ref Number"}} visible={isFieldVisible("referencesEns.commercialReferenceNumber")}>
                <CustomRule message={getErrorMessage("commercialReferenceNumber", fieldErrors)} ignoreEmptyValue={false} validationCallback={() => {return validationRules.commercialReferenceNumberRule}} />
            </Item>
            <Item dataField="referencesEns.additionalInformation" label={{text: "Additional Information"}} visible={isFieldVisible("referencesEns.additionalInformation")}>
                <CustomRule message={getErrorMessage("additionalInformation", fieldErrors)} ignoreEmptyValue={false} validationCallback={() => {return validationRules.additionalInformationRule}} />
            </Item>
            <Item dataField="referencesEns.transportDocumentType" label={{text: "Transport Doc Type"}} visible={isFieldVisible("referencesEns.transportDocumentType")}>
                <CustomRule message={getErrorMessage("transportDocumentType", fieldErrors)} ignoreEmptyValue={false} validationCallback={() => {return validationRules.transportDocumentTypeRule}} />
            </Item>
            <Item dataField="referencesEns.transportDocumentNumber" label={{text: "Transport Doc Number"}}  visible={isFieldVisible("referencesEns.transportDocumentNumber")}>
                <CustomRule message={getErrorMessage("transportDocumentNumber", fieldErrors)} ignoreEmptyValue={false} validationCallback={() => {return validationRules.transportDocumentNumberRule}} />
            </Item>
            <Item dataField="lrn" label={{text: lrnText}} editorOptions={lrnOptions}  visible={isFieldVisible("localReferenceNumber")} >
                <CustomRule message={getErrorMessage("localReferenceNumber", fieldErrors)} ignoreEmptyValue={false} validationCallback={() => {return validationRules.lrnRule}} />
            </Item>
            <Item dataField="referencesEns.conveyanceReferenceNumber" label={{text: "Conveyance Ref Number"}}  visible={isFieldVisible("referencesEns.conveyanceReferenceNumber")}>
                <CustomRule message={getErrorMessage("conveyanceReferenceNumber", fieldErrors)} ignoreEmptyValue={false} validationCallback={() => {return validationRules.conveyanceReferenceNumberRule}} />
            </Item>
        </GroupItem>
    </Form>
}