import Form, {
    GroupItem,
    Item, Label
} from "devextreme-react/form"
import { Button } from 'devextreme-react'
import { MRNForm } from "../../app/models/clearances"
import { useStore } from "../../app/stores/store"

interface Props {
    dataForm: MRNForm | null
    handleCancelAdd: () => void
    handleConfirmAdd: (data: MRNForm) => void
}


export default function PortClearanceAddMrn({ dataForm, handleCancelAdd, handleConfirmAdd }: Props) {

    const { commonStore } = useStore()

    function handleFormSubmit() {
        // Do validation...
        if (dataForm)
            handleConfirmAdd(dataForm)
    }

    const actionTypes = [
        { id: 1, code: 'Import' },
        { id: 2, code: 'Export' },
        { id: 3, code: 'Transit'}
    ]

    return (
        <div>
            <Form formData={dataForm}>
                <GroupItem colCount={2}>
                    <Item
                        dataField="actionType"
                        editorType="dxSelectBox"
                        label={{ text: 'Type' }}
                        editorOptions={{
                            items: actionTypes,
                            searchEnabled: true,
                            displayExpr: "code",
                            valueExpr: "code",
                        }}
                    />
                    <Item dataField="mrnNumber" label={{ text: 'MRN Number' }} />
                    <Item dataField="sasMrnNumber" label={{ text: 'S&S MRN Number' }} />
                    <Item
                        dataField="declarationType"
                        editorType="dxSelectBox"
                        label={{ text: 'Declaration Type' }}
                        editorOptions={{
                            items: commonStore.portDeclarationTypes,
                            searchEnabled: true,
                            displayExpr: "description",
                            valueExpr: "code",
                        }}
                    />
                    <Item
                        dataField="isTsad"
                        editorType="dxCheckBox"
                        editorOptions={{
                            text: 'Is Tsad?',
                            value: dataForm?.isTsad
                        }}
                    >
                        <Label visible={false} />
                    </Item>
                    <Item >
                        <Button
                            onClick={handleFormSubmit}
                            type="default"
                            className="dx-widget dx-button dx-button-mode-contained dx-button-has-text myc-button-left"
                        >
                            <div className="dx-button-text">{'Save'}</div>
                        </Button>
                        <Button
                            onClick={handleCancelAdd}
                            type="normal"
                            className="dx-widget dx-button dx-button-mode-contained dx-button-has-text"
                        >
                            <div className="dx-button-text">Cancel</div>
                        </Button>
                    </Item>
                </GroupItem>
            </Form>
        </div>
    )
}
