import { Button, DataGrid } from "devextreme-react";
import Form, { GroupItem, Item, } from 'devextreme-react/form'
import { methodOfPaymentOptions } from "../../../../app/common/options/cdsImportDeclaration/methodOfPaymentOptions";
import { modeOfBorderTransportOptions } from "../../../../app/common/options/cdsImportDeclaration/modeOfBorderTransportOptions";
import { customsOfficeCodeOptions } from "../../../../app/common/options/safetyAndSecurity/customsOfficeCodeOptions";
import { DeclarationGoods, DeclarationRequest, FieldError } from "../../../../app/models/declarationCreate";
import { Column, CustomRule, Editing } from "devextreme-react/data-grid";
import CustomsSummary from "../../CustomsSummary";
import SafetyAndSecurityParties from "../SafetyAndSecurityParties";
import { useEffect, useRef, useState } from "react";
import { getErrorMessageWithSequence, isFieldValidWithSequence } from "../../../../app/common/utils/declarationErrorFunctions";
import ValidationEngine from 'devextreme/ui/validation_engine';

interface Props{
    formData: DeclarationGoods | null;
    importData: DeclarationRequest;
    goodsItemId: number;
    fieldErrors: FieldError[]
    closeFunction: (goodsItemAdded: DeclarationGoods | null) => void;
    isFieldVisible: (fieldName: string) => boolean;
}


function SafetyAndSecurityGoodsEdit({formData,importData, goodsItemId, fieldErrors, closeFunction, isFieldVisible }: Props){
    const addBtnText = (formData == null) ? "Add" : "Update";
    const [isFormReady, setIsFormReady] = useState(false)
    const formRef = useRef<Form>(null)

    const currentValidateForm = () => {
        if(formRef.current && ValidationEngine.getGroupConfig(formRef.current.instance)){
            formRef.current.instance.validate()
        }
    }

    useEffect(() => {
    if(isFormReady)
            currentValidateForm()
    });

    const validationRules: {
        goodsItemNumberRule: boolean
        commercialReferenceNumberRule: boolean
        additionalInformationRule: boolean
        transportDocumentTypeRule: boolean
        transportDocumentNumberRule: boolean
        localReferenceNumberRule: boolean
        methodOfPaymentRule: boolean
        arrivalAtFirstPlaceRule: boolean
        placeOfloadingRule: boolean
        placeOfUnloadingRule: boolean
        grossMassRule: boolean
        commodityCodeDescriptionRule: boolean
        numberOfPiecesRule: boolean
        combinedNomenclatureCodeRule: boolean
        unDangerousRule: boolean
        identityOfMeansTransportOnArrivalRule: boolean
        modeOfTransportAtBorderRule: boolean
        containerIdRule: boolean
        sealNumberRule: boolean
    } = {
        goodsItemNumberRule: isFieldValidWithSequence("goodsItemNumber", formData?.itemDetail.messageInformation.goodsItemNumber ?? 0, fieldErrors),
        commercialReferenceNumberRule: isFieldValidWithSequence("commercialReferenceNumber", formData?.itemDetail.messageInformation.goodsItemNumber ?? 0, fieldErrors),
        additionalInformationRule: isFieldValidWithSequence("additionalInformation", formData?.itemDetail.messageInformation.goodsItemNumber ?? 0, fieldErrors),
        transportDocumentTypeRule: isFieldValidWithSequence("transportDocumentType", formData?.itemDetail.messageInformation.goodsItemNumber ?? 0, fieldErrors),
        transportDocumentNumberRule: isFieldValidWithSequence("transportDocumentNumber", formData?.itemDetail.messageInformation.goodsItemNumber ?? 0, fieldErrors),
        localReferenceNumberRule: isFieldValidWithSequence("localReferenceNumber", formData?.itemDetail.messageInformation.goodsItemNumber ?? 0, fieldErrors),
        methodOfPaymentRule: isFieldValidWithSequence("methodOfPayment", formData?.itemDetail.messageInformation.goodsItemNumber ?? 0, fieldErrors),
        arrivalAtFirstPlaceRule: isFieldValidWithSequence("arrivalAtFirstPlace", formData?.itemDetail.messageInformation.goodsItemNumber ?? 0, fieldErrors),
        placeOfloadingRule: isFieldValidWithSequence("placeOfloading", formData?.itemDetail.messageInformation.goodsItemNumber ?? 0, fieldErrors),
        placeOfUnloadingRule: isFieldValidWithSequence("placeOfUnloading", formData?.itemDetail.messageInformation.goodsItemNumber ?? 0, fieldErrors),
        grossMassRule: isFieldValidWithSequence("grossMass", formData?.itemDetail.messageInformation.goodsItemNumber ?? 0, fieldErrors),
        commodityCodeDescriptionRule: isFieldValidWithSequence("commodityCodeDescription", formData?.itemDetail.messageInformation.goodsItemNumber ?? 0, fieldErrors),
        numberOfPiecesRule: isFieldValidWithSequence("numberOfPieces", formData?.itemDetail.messageInformation.goodsItemNumber ?? 0, fieldErrors),
        combinedNomenclatureCodeRule: isFieldValidWithSequence("combinedNomenclatureCode", formData?.itemDetail.messageInformation.goodsItemNumber ?? 0, fieldErrors),
        unDangerousRule: isFieldValidWithSequence("unDangerous", formData?.itemDetail.messageInformation.goodsItemNumber ?? 0, fieldErrors),
        identityOfMeansTransportOnArrivalRule: isFieldValidWithSequence("identityOfMeansTransportOnArrival", formData?.itemDetail.messageInformation.goodsItemNumber ?? 0, fieldErrors),
        modeOfTransportAtBorderRule: isFieldValidWithSequence("modeOfTransportAtBorder", formData?.itemDetail.messageInformation.goodsItemNumber ?? 0, fieldErrors),
        containerIdRule: isFieldValidWithSequence("containerId", formData?.itemDetail.messageInformation.goodsItemNumber ?? 0, fieldErrors),
        sealNumberRule: isFieldValidWithSequence("sealNumber", formData?.itemDetail.messageInformation.goodsItemNumber ?? 0, fieldErrors)
    };

    if(formData == null)
    {
        formData = {
            itemDetail:{
                messageInformation : {
                    goodsItemNumber: goodsItemId,
                    procedures: null,
                    additionalProcedure: null
                },
                referencesEns : {
                    commercialReferenceNumber : '',
                    additionalInformation : '',
                    transportDocumentType : '',
                    transportDocumentNumber : '',
                    conveyanceReferenceNumber : ''
                },
                parties : {
                    exporter: null,
                    importer: null,
                    buyer: null,
                    seller: null,
                    carrier: null,
                    notifyParty: null,
                    declarant: null,
                    representative: null,
                    additionalSupplyChainActorId: null,
                    additionalSupplyChainActorRole: null
                },
                valuationInformationTaxes: {
                    methodOfPayment: '',
                    personPayingCustomsDuty: '',
                    personProvidingGuarantee: '',
                    warehouseType: '',
                    taxBaseAmount: [],
                    preference: null,
                    calculationOfTaxes: {
                        payableTaxAmount: [],
                        totalTaxCalculated: null,
                        currency: null,
                        itemPriceAmount: null,
                        valuationIndicators: null,
                        additionsAndReductions: [],
                        totalAmountInvoiced: null
                    },
                    valuationMethod: null
                },
                places: {
                    countriesOfRouting: null,
                    placeOfLoading: null,
                    placeOfUnloading: null,
                    countryDestination: null,
                    countryDispatch: null,
                    countryOfOrigin: null,
                    countryOfPreferentialOrigin: null,
                    countryOfOriginType: null,
                    countryOfPreferentialOriginType: null,
                    arrivalAtFirstPlace: null
                },
                goodsIdentification: {
                    grossMass: null,
                    numberOfPieces: null,
                    commodityCode: {
                        combinedNomenclatureCode: '',
                        description: null,
                        taricCode: null,
                        cusCode: null,
                        cusType: null,
                        taricAdditionalCode: null,
                        nationalAdditionalCode: null
                    },
                    packages: [],
                    netMass: null,
                    supplementaryUnits: null,
                    unDangerous: null
                },
                transportInformation: {
                    containerId: null,
                    sealNumber: []
                },
                references: {
                    references: [],
                    additionalReference: {
                        code: null,
                        description: null
                    },
                    ucrNumber: null,
                    referencesEns: {
                        commercialReferenceNumber : '',
                        additionalInformation : '',
                        transportDocumentType : '',
                        transportDocumentNumber : '',
                        conveyanceReferenceNumber : ''
                    }
                },
                otherDataElements: {
                    natureOfTransaction: null,
                    quotaOrderNumber: null,
                    statisticalValue: null
                },
                summary: {
                    customsRequested: null,
                    mrn: null,
                    jobStatus: null,
                    customs: null,
                    haulierRequest: null,
                    haulierResponse: null,
                    fromTo: null,
                    lastUpdate: null,
                    authorityMessage: null
                }
            }
        }
    }

    function saveGoodsItem(){
        if(formData != null)
          closeFunction(formData);
      }

      function cancelGoodsItem(){
        closeFunction(null);
      }

const irelandCustomsOfficeCodeOptions = customsOfficeCodeOptions.filter(x => x.country == "IE" || x.country == "NI");
const unitedKingdomCustomsOfficeCodeOptions = customsOfficeCodeOptions.filter(x => x.country == "GB");
const goodsItemNumber = formData.itemDetail.messageInformation.goodsItemNumber;

    return <Form ref={formRef} formData={formData.itemDetail} onContentReady={() => {setIsFormReady(true)}}>
        <GroupItem colCount={2}>
            <GroupItem>
                <GroupItem cssClass="cds-group-item" colCount={2} caption={'Goods'} >
                    <Item dataField="messageInformation.goodsItemNumber" label={{text: "Item Number"}} editorType="dxNumberBox" editorOptions={{ readOnly: true}}>
                        <CustomRule message={getErrorMessageWithSequence("goodsItemNumber", goodsItemNumber, fieldErrors)} ignoreEmptyValue={false} validationCallback={() => {return validationRules.goodsItemNumberRule}} />
                    </Item>
                </GroupItem>
                <GroupItem cssClass="cds-group-item" colCount={2} caption={'References'} >
                    <Item dataField="references.referencesEns.commercialReferenceNumber" label={{text: "Commercial Ref #"}} visible={isFieldVisible("references.referencesEns.commercialReferenceNumber")} >
                        <CustomRule message={getErrorMessageWithSequence("commercialReferenceNumber", goodsItemNumber, fieldErrors)} ignoreEmptyValue={false} validationCallback={() => {return validationRules.commercialReferenceNumberRule}} />
                    </Item>
                    <Item dataField="references.referencesEns.additionalInformation" label={{text: "Additional Information"}} visible={isFieldVisible("references.referencesEns.additionalInformation")}>
                        <CustomRule message={getErrorMessageWithSequence("additionalInformation", goodsItemNumber, fieldErrors)} ignoreEmptyValue={false} validationCallback={() => {return validationRules.additionalInformationRule}} />
                    </Item>
                    <Item dataField="references.referencesEns.transportDocumentType" label={{text: "Transport Doc Type"}} visible={isFieldVisible("references.referencesEns.transportDocumentType")} >
                        <CustomRule message={getErrorMessageWithSequence("transportDocumentType", goodsItemNumber, fieldErrors)} ignoreEmptyValue={false} validationCallback={() => {return validationRules.transportDocumentTypeRule}} />
                    </Item>
                    <Item dataField="references.referencesEns.transportDocumentNumber" label={{text: "Transport Doc Number"}} visible={isFieldVisible("references.referencesEns.transportDocumentNumber")}>
                        <CustomRule message={getErrorMessageWithSequence("transportDocumentNumber", goodsItemNumber, fieldErrors)} ignoreEmptyValue={false} validationCallback={() => {return validationRules.transportDocumentNumberRule}} />
                    </Item>
                    <Item dataField="references.referencesEns.localReferenceNumber" label={{text: "Local Reference #"}} visible={isFieldVisible("references.referencesEns.localReferenceNumber")}>
                        <CustomRule message={getErrorMessageWithSequence("localReferenceNumber", goodsItemNumber, fieldErrors)} ignoreEmptyValue={false} validationCallback={() => {return validationRules.localReferenceNumberRule}} />
                    </Item>
                </GroupItem>
                <GroupItem><SafetyAndSecurityParties formData={formData.itemDetail.parties} fieldErrors={fieldErrors} isFieldVisible={isFieldVisible}/></GroupItem>
                <GroupItem cssClass="cds-group-item" colCount={2} caption={'Valuation Information Taxes'}  >
                    <Item dataField="valuationInformationTaxes.methodOfPayment" label={{text: "Method of Payment"}} editorType="dxSelectBox"
                        visible={isFieldVisible("valuationInformationTaxes.methodOfPayment")}
                        editorOptions={{
                            items: methodOfPaymentOptions,
                            searchEnabled: true,
                            displayExpr: "description",
                            valueExpr: "code",
                    }}>
                        <CustomRule message={getErrorMessageWithSequence("methodOfPayment", goodsItemNumber, fieldErrors)} ignoreEmptyValue={false} validationCallback={() => {return validationRules.methodOfPaymentRule}} />
                    </Item>
                </GroupItem>
                <GroupItem cssClass="cds-group-item" colCount={2} caption={'Dates And Times'} visible={isFieldVisible("places.arrivalAtFirstPlace")} >
                    <Item dataField="places.arrivalAtFirstPlace" label={{text: "Arrival At First Place"}} editorType="dxDateBox">
                        <CustomRule message={getErrorMessageWithSequence("arrivalAtFirstPlace", goodsItemNumber, fieldErrors)} ignoreEmptyValue={false} validationCallback={() => {return validationRules.arrivalAtFirstPlaceRule}} />
                    </Item>
                </GroupItem>
                <GroupItem cssClass="cds-group-item" colCount={2} caption={'Places'} >
                    <Item dataField="places.placeOfloading" label={{text: "Place of loading"}} editorType="dxSelectBox" 
                        visible={isFieldVisible("places.placeOfloading")}
                        editorOptions={{
                            items: irelandCustomsOfficeCodeOptions,
                            searchEnabled: true,
                            displayExpr: "description",
                            valueExpr: "code",
                        }}>
                            <CustomRule message={getErrorMessageWithSequence("placeOfloading", goodsItemNumber, fieldErrors)} ignoreEmptyValue={false} validationCallback={() => {return validationRules.placeOfloadingRule}} />
                        </Item>
                    <Item dataField="places.placeOfUnloading" label={{text: "Place of unloading"}} editorType="dxSelectBox" 
                        visible={isFieldVisible("places.placeOfUnloading")}
                        editorOptions={{
                            items: unitedKingdomCustomsOfficeCodeOptions,
                            searchEnabled: true,
                            displayExpr: "description",
                            valueExpr: "code",
                        }}>
                            <CustomRule message={getErrorMessageWithSequence("placeOfUnloading", goodsItemNumber, fieldErrors)} ignoreEmptyValue={false} validationCallback={() => {return validationRules.placeOfUnloadingRule}} />
                        </Item>
                </GroupItem>
                <GroupItem cssClass="cds-group-item" colCount={2} caption={'Goods Identification'} >
                    <Item dataField="goodsIdentification.grossMass" label={{text: "Gross Mass"}} editorType="dxNumberBox" visible={isFieldVisible("goodsIdentification.grossMass")} >
                        <CustomRule message={getErrorMessageWithSequence("grossMass", goodsItemNumber, fieldErrors)} ignoreEmptyValue={false} validationCallback={() => {return validationRules.grossMassRule}} />
                    </Item>
                    <Item dataField="goodsIdentification.commodityCode.description" label={{text: "Goods Description"}} visible={isFieldVisible("goodsIdentification.commodityCode.description")}>
                        <CustomRule message={getErrorMessageWithSequence("commodityCodeDescription", goodsItemNumber, fieldErrors)} ignoreEmptyValue={false} validationCallback={() => {return validationRules.commodityCodeDescriptionRule}} />
                    </Item>
                    <Item dataField="goodsIdentification.numberOfPieces" label={{text: "Num of packages"}} editorType="dxNumberBox" visible={isFieldVisible("goodsIdentification.numberOfPieces")}>
                        <CustomRule message={getErrorMessageWithSequence("numberOfPieces", goodsItemNumber, fieldErrors)} ignoreEmptyValue={false} validationCallback={() => {return validationRules.numberOfPiecesRule}} />
                    </Item>
                    <Item dataField="goodsIdentification.commodityCode.combinedNomenclatureCode" label={{text: "Commodity Code"}} visible={isFieldVisible("goodsIdentification.commodityCode.combinedNomenclatureCode")}>
                        <CustomRule message={getErrorMessageWithSequence("combinedNomenclatureCode", goodsItemNumber, fieldErrors)} ignoreEmptyValue={false} validationCallback={() => {return validationRules.combinedNomenclatureCodeRule}} />
                    </Item>
                    <Item dataField="goodsIdentification.unDangerous" label={{text: "UN Dangerous"}} visible={isFieldVisible("goodsIdentification.unDangerous")}>
                        <CustomRule message={getErrorMessageWithSequence("unDangerous", goodsItemNumber, fieldErrors)} ignoreEmptyValue={false} validationCallback={() => {return validationRules.unDangerousRule}} />
                    </Item>
                </GroupItem>
                <GroupItem cssClass="cds-group-item" colCount={2} caption={'Packages'} visible={isFieldVisible("goodsIdentification.packages")} >
                    <DataGrid dataSource={formData.itemDetail.goodsIdentification.packages} >
                        <Editing allowAdding={true} allowDeleting={true} allowUpdating={true} >
                        </Editing>
                        <Column dataField='shippingMarks' caption={'Shipping Marks'} defaultSortIndex={0} defaultSortOrder="asc"></Column>
                        <Column dataField='type' caption={'Type'}></Column>
                        <Column dataField='numberPackages' caption={'Packages #'}></Column>
                    </DataGrid>
                </GroupItem>
                <GroupItem cssClass="cds-group-item" colCount={2} caption={'Transport Information'} >
                    <Item dataField="transportInformation.identityOfMeansTransportOnArrival" label={{text: "Id Cross Border Transport"}} visible={isFieldVisible("transportInformation.identityOfMeansTransportOnArrival")}>
                        <CustomRule message={getErrorMessageWithSequence("identityOfMeansTransportOnArrival", goodsItemNumber, fieldErrors)} ignoreEmptyValue={false} validationCallback={() => {return validationRules.identityOfMeansTransportOnArrivalRule}} />
                    </Item>
                    <Item dataField="transportInformation.modeOfTransportAtBorder" label={{text: "Mode Of Border Transport"}} editorType="dxSelectBox" 
                        visible={isFieldVisible("transportInformation.modeOfTransportAtBorder")}
                        editorOptions={{
                            items: modeOfBorderTransportOptions,
                            searchEnabled: true,
                            displayExpr: "description",
                            valueExpr: "code",
                        }}>
                            <CustomRule message={getErrorMessageWithSequence("modeOfTransportAtBorder", goodsItemNumber, fieldErrors)} ignoreEmptyValue={false} validationCallback={() => {return validationRules.modeOfTransportAtBorderRule}} />
                        </Item>
                    <Item dataField="transportInformation.containerId" label={{text: "Container Number"}} visible={isFieldVisible("transportInformation.containerId")}>
                        <CustomRule message={getErrorMessageWithSequence("containerId", goodsItemNumber, fieldErrors)} ignoreEmptyValue={false} validationCallback={() => {return validationRules.containerIdRule}} />
                    </Item>
                    <Item dataField="transportInformation.sealNumber" label={{text: "Seal Number"}} visible={isFieldVisible("transportInformation.sealNumber")}>
                        <CustomRule message={getErrorMessageWithSequence("sealNumber", goodsItemNumber, fieldErrors)} ignoreEmptyValue={false} validationCallback={() => {return validationRules.sealNumberRule}} />
                    </Item>
                </GroupItem>
            </GroupItem>
            <GroupItem>
                <CustomsSummary formData={importData.request.importDeclarationData} />
                <Button onClick={saveGoodsItem} text={addBtnText} />
                <Button onClick={cancelGoodsItem} text={'Cancel'} />
            </GroupItem>
        </GroupItem>
    </Form>
}

export default SafetyAndSecurityGoodsEdit;
