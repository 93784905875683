import { observer } from 'mobx-react-lite'
import { useStore } from '../../app/stores/store'
import Form, { GroupItem } from 'devextreme-react/form'
import { useEffect, useState } from 'react'
import { Button, DataGrid, LoadIndicator } from 'devextreme-react'
import { Column, Toolbar, Item, FilterRow } from 'devextreme-react/data-grid'
import Menu, {Item as MenuItem} from 'devextreme-react/menu';
import ContextMenu from 'devextreme-react/context-menu';
import { CopyDeclaration } from '../../app/models/declarationCopy'
import { notifyMessage } from '../../app/common/utils/notify'
import { router } from '../../app/router/Routes'
import { confirm } from "devextreme/ui/dialog"
import { useDeclarations, useDeleteDeclaration } from '../../app/hooks/declarationQueries'

export default observer(function DeclarationsMain() {
    const [selectedDeclarationId, setSelectedDeclarationId] = useState('');
    const {declarationStore, organisationStore } = useStore()
    const deleteDeclaration = useDeleteDeclaration()

    useEffect(() => {
        organisationStore.getOrganisationMe();
    }, [organisationStore]);

    let orgDefault = (organisationStore.organisations?.find(org => org.isDefault));
    let organisationId = (orgDefault) ? orgDefault.id : '';

    const {isLoading, refetch } = useDeclarations(organisationId);
    if (!organisationId || isLoading) return <LoadIndicator className="organisations-load-indicator" />

    const rightClickItems = [{text: 'Copy'}, {text: 'Create template'}];

    const handleAddDeclaration = (authoritySystem: string, isImport: boolean, isExs: boolean) => {
      router.navigate(`/declarations/${authoritySystem}/${isImport}/${isExs}`)
    }

    const handleAddEns = (authoritySystem: string) => {
      router.navigate(`/declarations/ens/authority/${authoritySystem}`)
    }

    const handleAddRoro = () => {
      router.navigate(`/declarations-roro`)
    }

    function handleSubmit(e: any) {
        e.preventDefault()
      }

      async function refreshData(){
        refetch()
      }

      function handleImportClick(authoritySystem: string){
        handleAddDeclaration(authoritySystem, true, false);
      }

      function handleExportClick(authoritySystem: string){
        handleAddDeclaration(authoritySystem, false, false);
      }

      function handleAddEnsClick(authoritySystem: string){
        handleAddEns(authoritySystem);
      }

      function handleAddExsClick(authoritySystem: string){
        handleAddDeclaration(authoritySystem, false, true);
      }

      function handleRightClick(e: any){
        if(e.itemData.text == 'Copy')
        {
          handleCopyClick();
        }
        else if(e.itemData.text == 'Create template')
        {
          handleSendToTemplatesClick();
        }
      }

      function handleCopyClick(){
        if(selectedDeclarationId != null)
        {
            let declarationToCopy: CopyDeclaration = {declarationId: selectedDeclarationId}
            declarationStore.copyDeclaration(declarationToCopy).then(() => notifyMessage("success", "Declaration copied")).finally(() => {refreshData()})
        }
      }

      function handleSendToTemplatesClick(){
        console.log('create template from: ', selectedDeclarationId)
        if(selectedDeclarationId != null)
          {
              declarationStore.createTemplate(selectedDeclarationId).then(() => notifyMessage("success", "Declaration template created")).finally(() => {refreshData()})
          }
      }

    const onItemClick = (e: any) => {
      if (e.itemData.text == 'CDS-IM') {
        handleImportClick('CDS');
      }
      else if (e.itemData.text == 'AIS-IM') {
        handleImportClick('AIS');
      }
      else if (e.itemData.text == 'AES-EX') {
        handleExportClick('AES');
      }
      else if (e.itemData.text == 'CDS-EX') {
        handleExportClick('CDS');
      }
      else if (e.itemData.text == 'ICS-ENS') {
        handleAddEnsClick('ICS');
      }
      else if (e.itemData.text == 'ICS(GB)-ENS') {
        handleAddEnsClick('CDS');
      }
      else if (e.itemData.text == 'CDS-EXS') {
        handleAddExsClick('CDS');
      }
      else if (e.itemData.text == 'AES-EXS') {
        handleAddExsClick('AES');
      }
      else if(e.itemData.text == 'RORO'){
        handleAddRoro();
      }
  }

  const handleEdit = (cellData: any) => {
    console.log('celldata Id: ' + cellData.data.id)
    let matchedItem = declarationStore.declarations.find((element) => {
      return element.id == cellData.data.id;
    })

    if(matchedItem === undefined)
    {
      return;
    }

    if (cellData.data.id) {
      if(matchedItem.declarationType != 'ENS')
      {
        router.navigate(`/declarations/${cellData.data.id}`)
      }
      else
      {
        router.navigate(`/declarations/ens/${cellData.data.id}`)
      }
    }
  }

  const handleDelete = (cellData: any) => {
    confirm("<i>Are you sure?</i>", "Confirm changes").then((result) => {
        if (cellData.data.id && result) {
            //declarationStore.delete(cellData.data.id)
            deleteDeclaration.mutateAsync(cellData.data.id)
        }
    })
  }

  const renderButton = (cellData: any) => {
    return (
        <>
            <Button
                icon="edit"
                width={40}
                type="normal"
                stylingMode="contained"
                onClick={() => handleEdit(cellData)}
            />
            <Button
                icon="trash"
                width={40}
                type="normal"
                stylingMode="contained"
                onClick={() => handleDelete(cellData)}
            />
        </>
    )
}

    return (
    <div className='content-block'>
        {(declarationStore.loading || isLoading) && <LoadIndicator className="organisations-load-indicator" />}
        <form onSubmit={(e) => handleSubmit(e)}>
                    <Form formData={declarationStore.declarations}>
                        <GroupItem caption={'Declarations'} >
                          <Menu onItemClick={onItemClick} >
                              <MenuItem icon="add" text={'Import'} >
                                <MenuItem icon="add" text={'CDS-IM'} ></MenuItem>
                                <MenuItem icon="add" text={'AIS-IM'} ></MenuItem>
                              </MenuItem>
                              <MenuItem icon="add" text={'Export'} >
                                <MenuItem icon="add" text={'AES-EX'} ></MenuItem>
                                <MenuItem icon="add" text={'CDS-EX'} ></MenuItem>
                              </MenuItem>
                              <MenuItem icon="add" text={'ENS'} >
                                <MenuItem icon="add" text={'ICS-ENS'} ></MenuItem>
                                <MenuItem icon="add" text={'ICS(GB)-ENS'} ></MenuItem>
                              </MenuItem>
                              <MenuItem icon="add" text={'EXS'} >
                                <MenuItem icon="add" text={'CDS-EXS'} ></MenuItem>
                                <MenuItem icon="add" text={'AES-EXS'} ></MenuItem>
                              </MenuItem>
                              <MenuItem icon="add" text={'RORO'} ></MenuItem>
                          </Menu>
                            <DataGrid id='declarations' dataSource={declarationStore.declarations} rowAlternationEnabled={true} keyExpr='id' onContextMenuPreparing={(rows) => setSelectedDeclarationId(rows.row?.key)} >
                                <FilterRow visible={true} />
                                <Column dataField="localReferenceNumber" caption={'Reference'} width={'150'}></Column>
                                <Column dataField="authoritySystem" caption={'Authority'}></Column>
                                <Column dataField="declarationType" caption={'Dec.'} ></Column>
                                <Column dataField="type" caption={'Type'}></Column>
                                <Column dataField="additionalType" caption={'Add. Type'}></Column>
                                <Column dataField="movementReferenceNumber" caption={'MRN'} width={'200'}></Column>
                                <Column dataField="lastAuthorityMessage" caption={'Authority Message'}></Column>
                                <Column dataField="requestedBy" caption={'Req. By'}></Column>
                                <Column dataField="requestedDate" caption={'Req. Date'} dataType="date" format="dd/MM/yy"></Column>
                                <Column dataField="exporterEori" caption={'Exporter'}></Column>
                                <Column dataField="importerEori" caption={'Importer'}></Column>
                                <Column dataField="countryOfDispatch" caption={'From'}></Column>
                                <Column dataField="countryOfDestination" caption={'To'}></Column>
                                <Column type={'buttons'} cellRender={(e) => renderButton(e)} />
                                <Toolbar>
                                    <Item>
                                        <Button icon='refresh' hint='Refresh' onClick={() => refreshData()}></Button>
                                    </Item>
                                </Toolbar>
                            </DataGrid>
                        </GroupItem>
                    </Form>
                </form>
            <ContextMenu dataSource={rightClickItems} target="#declarations" onItemClick={handleRightClick} />
        </div >
        )
})
