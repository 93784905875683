import { observer } from 'mobx-react-lite'
import { PortClearanceDataForm } from '../../app/models/clearances'
import Form, {
    GroupItem,
    Item,
} from "devextreme-react/form"
import { useStore } from '../../app/stores/store'
import { FieldDataChangedEvent } from 'devextreme/ui/form'
import { useEffect, useState } from 'react'
import { CustomsPort } from '../../app/models/customsPorts'


interface Props {
    dataForm: PortClearanceDataForm | undefined
    exitPorts: CustomsPort[] | undefined
    entryPorts: CustomsPort[] | undefined
    handleRouteChange: (newRoute: string) => void
}

export default observer(function PortClearanceRouteForm({ dataForm, entryPorts, exitPorts, handleRouteChange }: Props) {
    const { clearanceStore, commonStore } = useStore()
    const [emailDisable, setEmailDisable] = useState<boolean>(true)
    const [mobileDisable, setMobileDisable] = useState<boolean>(true)

    useEffect(() => {
        if (clearanceStore.portClearance?.notifyDriver)
            setNotifyTypeDisables(clearanceStore.portClearance?.notifyDriver)
    }, [clearanceStore.portClearance])



    function setNotifyTypeDisables(newValue: string) {
        if (newValue === 'None') {
            setEmailDisable(true)
            setMobileDisable(true)
        } else if (newValue === 'Email') {
            setEmailDisable(false)
            setMobileDisable(true)
        } else if (newValue === 'Mobile') {
            setEmailDisable(true)
            setMobileDisable(false)
        } else if (newValue === 'Both') {
            setEmailDisable(false)
            setMobileDisable(false)
        }
    }

    function formFieldDataChanged(e: FieldDataChangedEvent) {
        const updatedField = e.dataField
        const newValue = e.value
        if (updatedField === 'customsRouteId') {
            handleRouteChange(newValue)
        } else if (updatedField === 'notifyMethod') {
            setNotifyTypeDisables(newValue)
        }
    }

    return (

        <Form formData={dataForm} onFieldDataChanged={formFieldDataChanged} >
            <GroupItem colCount={2}>
                <Item
                    dataField="customsRouteId"
                    editorType="dxSelectBox"
                    label={{ text: 'Route' }}
                    editorOptions={{
                        items: clearanceStore.customsRoutes,
                        searchEnabled: true,
                        displayExpr: "name",
                        valueExpr: "id",
                    }}
                />
                <Item
                    dataField="customsCarrierId"
                    editorType="dxSelectBox"
                    label={{ text: 'Carrier' }}
                    editorOptions={{
                        items: clearanceStore.customsCarriers,
                        searchEnabled: true,
                        displayExpr: "name",
                        valueExpr: "id",
                    }}
                />

                <Item
                    dataField="exitTask.customsPortId"
                    editorType="dxSelectBox"
                    label={{ text: 'From Port' }}
                    editorOptions={{
                        items: exitPorts,
                        searchEnabled: true,
                        displayExpr: "name",
                        valueExpr: "id",
                    }}
                />

                <Item
                    dataField="entryTask.customsPortId"
                    editorType="dxSelectBox"
                    label={{ text: 'To Port' }}
                    editorOptions={{
                        items: entryPorts,
                        searchEnabled: true,
                        displayExpr: "name",
                        valueExpr: "id",
                    }}
                />
                <Item
                    dataField="actionDate"
                    editorType="dxDateBox"
                    editorOptions={{
                        dateSerializationFormat: "yyyy-MM-ddTHH:mm:ssZ",
                        displayFormat: "dd/MM/yyyy HH:mm",

                        type: "datetime"
                    }}
                />
                <Item colSpan={2}
                    dataField="notifyMethod"
                    editorType="dxSelectBox"
                    label={{ text: 'Notify Method' }}

                    editorOptions={{
                        items: commonStore.notifyDriverTypes,
                        searchEnabled: true,
                        displayExpr: "description",
                        valueExpr: "code",
                    }}
                />
                <Item dataField="notifyEmail" colSpan={2}
                    label={{ text: 'Contact Email' }}
                    editorOptions={{ disabled: emailDisable }}

                />
                <Item dataField="notifyMobileNumber" colSpan={2}
                    label={{ text: 'Contact Mobile Number' }}
                    editorOptions={{ disabled: mobileDisable }}
                />
            </GroupItem>
        </Form>

    )
})