import { Button, CheckBox } from "devextreme-react";
import { Item, RequiredRule } from "devextreme-react/data-grid";
import { observer } from "mobx-react-lite";
import { createRef, useEffect, useState } from "react";
import { useStore } from "../../app/stores/store";
import Form, {
  ButtonItem,
  GroupItem,
  Item as FormItem,
  SimpleItem,
  Label,
} from "devextreme-react/form";
import FormContainer from "../../app/layout/components/FormContainer";
import { notifyMessage } from "../../app/common/utils/notify";
import { NewCredential } from "../../app/models/organisation";
import convertStringBase64 from "../../app/common/utils/convertStringBase64";
import { router } from "../../app/router/Routes";
import { credentialTypeOptions } from "../../app/common/options/credentialTypeOptions";
import { isVisible } from "../../app/common/options/declarationTemplates/declarationTemplate";

interface Props {
  organisationId: string;
  hmrcCode?: string;
  closeFunction: () => void;
}

const iniObject = {
  id: "",
  name: "",
  type: "None",
  credential: "",
  expiryDate: "",
};

const iniHmrcObject = {
  id: "",
  name: "",
  type: "HMRCUser",
  credential: "",
  expiryDate: "",
};

function AddCredentials({ organisationId, hmrcCode, closeFunction }: Props) {
  const formElement = createRef<HTMLFormElement>();
  const { organisationStore } = useStore();
  const [isDefault, setDefault] = useState(false);
  const [credentialType, setCredentialType] = useState(hmrcCode ? "HMRC User" : "None")
  const [selectedRowData, setSelectedRowData] = useState<any>(hmrcCode ? { ...iniHmrcObject, credential: hmrcCode } : { ...iniObject })


  useEffect(() => { }, [credentialType]);

  const handleSave = (e: any) => {
    e.preventDefault();
    const newCredential: NewCredential = {
      organisationId: organisationId,
      name: selectedRowData.name,
      type: selectedRowData.type,
      encodedCredential: convertStringBase64(selectedRowData.credential),
      expiryDate: selectedRowData.expiryDate
        ? selectedRowData.expiryDate
        : null,
      isDefault: isDefault,
    };
    organisationStore
      .createCredential(newCredential)
      .then(() => {
        notifyMessage("Success", "Credential added successfully");
        organisationStore.getCredentials(organisationId);
        handleCancel();
      })
      .catch((error) => {
        console.log(error);
        notifyMessage("error", "Error adding credential");
        handleCancel();
      });
  };

  const handleCancel = () => {
    closeFunction();
    setCredentialType("None");
    setSelectedRowData({ ...iniObject });
    setDefault(false);
    const url = organisationId
      ? `/organisation?o=${organisationId}&t=2`
      : `/organisation?t=2`;
    router.navigate(url);
  };

  const handleTypeChanged = (e: any) => {
    if (e) {
      setCredentialType(e.value);
      if (e.value.includes("HMRC")) {
        setSelectedRowData({
          ...selectedRowData,
          credential: hmrcCode,
        });
      }
    } else {
      setCredentialType("None");
      setSelectedRowData(iniObject);
      setDefault(false);
      const url = organisationId
        ? `/organisation?o=${organisationId}&t=2`
        : `/organisation?t=2`;
      router.navigate(url);
    }
  };

  const handleHmrcCodeRequest = () => {
    if (credentialType.includes("HMRC")) {
      const urlRequest = `${organisationStore.hmrc}?org=${organisationId}`;
      window.location.replace(urlRequest);
    }
  };

  const form = (
    <form onSubmit={handleSave} ref={formElement} id="form">
      <Form className="add-organisation-form" formData={selectedRowData || {}}>
        <GroupItem colCount={2} colSpan={2}>
          <SimpleItem
            dataField="type"
            editorType="dxSelectBox"
            editorOptions={{
              items: credentialTypeOptions,
              displayExpr: "display",
              valueExpr: "value",
              onValueChanged: handleTypeChanged,
              readOnly: (credentialType.includes("HMRC") && hmrcCode)
            }}
          >
            <RequiredRule message={"Credential Type is required"} />
          </SimpleItem>

          <SimpleItem dataField="name">
            <RequiredRule message={"Credential Name is required"} />
          </SimpleItem>

          <SimpleItem
            dataField="credential"
            editorOptions={{ readOnly: credentialType.includes("HMRC") }}
          >
            {credentialType.includes("HMRC") && <Label text="HMRC Access Code" />}
            {!credentialType.includes("HMRC") && (
              <RequiredRule message={"Credential is required"} />
            )}
          </SimpleItem>
        </GroupItem>
        <SimpleItem>
          <CheckBox
            id="check"
            defaultValue={isDefault}
            onValueChanged={() => setDefault(!isDefault)}
            text="Default"
          />
        </SimpleItem>
        <FormItem itemType={"empty"}></FormItem>

        {
          (credentialType && (!credentialType.includes("HMRC") || hmrcCode)) &&
          <ButtonItem
            cssClass={"add-organisation-save-btn"}
            horizontalAlignment="left"
            buttonOptions={{
              text: "Save",
              type: "default",
              useSubmitBehavior: true,
              width: 85,
              onClick: () => { }
            }}
          />
        }
        {(credentialType && credentialType.includes("HMRC") && (hmrcCode === undefined)) &&
          <ButtonItem
            cssClass={"add-organisation-save-btn"}
            horizontalAlignment="left"
            buttonOptions={{
              text: "Request",
              type: "default",
              useSubmitBehavior: false,
              width: 95,
              onClick: handleHmrcCodeRequest
            }}
          />}
        <Item cssClass={"add-organisation-cancel-btn"}>
          <Button
            onClick={handleCancel}
            type="normal"
            className="dx-widget dx-button dx-button-mode-contained dx-button-has-text"
          >
            <div className="dx-button-text">Cancel</div>
          </Button>
        </Item>
      </Form>
    </form>
  );

  return (
    <div className="html-blank-organisation">
      <div className="organisation-container">
        <FormContainer
          key={organisationId}
          children={form}
          title={"Add Credential"}
          closeFunction={handleCancel}
        />
      </div>
    </div>
  );
}
export default observer(AddCredentials);
